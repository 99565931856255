<template>
    <div class="inner-view">
        <div class="application-overview-header">
            <h3>{{ `${status} ${$t('Vacancies')}` }}</h3>
            <span class="rounded-badge">{{ vacancies.length }}</span>
        </div>
        <div class="row">
            <div class="col-6" v-for="vacancy in vacancies" :key="vacancy.id">
                <VacancyItem :vacancy="vacancy" @getCandidates="loadCandidates(vacancy)" />
            </div>
        </div>
    </div>
</template>

<script>
import VacancyItem from "@/components/applications/VacancyItemComponent";

export default {
    props: ['status', 'vacancies'],
    components: {
        VacancyItem
    },
    methods: {
        loadCandidates(vacancy) {
            this.$emit('loadCandidates', vacancy);
        },
    }
}
</script>