<template>
    <div class="item">
        <div class="item-wrapper">
            <div class="image">
                <img :src="vacancy.image" :alt="vacancy.title">
            </div>
            <div class="content">
                <p class="industry" v-if="vacancy.industry_name">{{ vacancy.industry_name }}</p>
                <h2 class="card-title viewable" @click="$router.push(`/vacancies/${slugify(vacancy.title)}-${vacancy.id}`)">{{ vacancy.title }}</h2>
                <ul class="item-list">
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.313" height="17.434" viewBox="0 0 13.313 17.434">
                            <g>
                                <g>
                                    <path d="M24.082 57.1v-3.2a2.934 2.934 0 0 1 2.832-2.9h7.648a2.934 2.934 0 0 1 2.832 2.9v3.2a10.722 10.722 0 0 1-13.312 0z" transform="translate(-24.082 -16.059) translate(24.082 16.059) translate(-24.082 -42.026)"/>
                                    <path d="M43.8 19.376c0 2.145-1.466 3.884-3.275 3.884s-3.275-1.739-3.275-3.884a3.276 3.276 0 1 1 6.551 0z" transform="translate(-24.082 -16.059) translate(24.082 16.059) translate(-33.866 -16.059)"/>
                                </g>
                            </g>
                        </svg>
                        <span>{{ vacancy.applications_count }}</span>
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199" viewBox="0 0 21.705 13.199">
                            <g>
                                <g>
                                    <path d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z" transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                </g>
                            </g>
                        </svg>
                        <span>views</span>
                    </li>
                </ul>

                <hr/>
                
                <button class="btn btn-secondary" type="button" @click="$emit('getCandidates')">{{ $t('View applications') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import slugify from "@/js/slugify";

export default {
    name: "VacancyComponent",
    data() {
        return {
            slugify: slugify,
        }
    },
    props: ['vacancy'],
}
</script>